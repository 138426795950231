import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Sticky, StickyContainer } from 'react-sticky'
import PuxTrumbowyg from '../components/shared/pux-trumbowyg'
import Picture from '../components/shared/picture'
import { Icon } from '../components/builders/icon-builder'
import { CaseStudyPageProps } from '../types/reference.types'
import Slider from 'react-slick'
import { t } from 'ttag'
import BreadCrumbs from '../components/BreadCrumbs'
import FormBuilder from '../components/builders/form-builder'
import JirkaPhoto from '../img/jirka.png'

import PLeft from '../img/_zdroj-left.png'
import PRight from '../img/_zdroj-right.png'
import { getLocalizedUrl } from '../utils/localeURL'

const CaseStudy = ({ data, pageContext }: CaseStudyPageProps) => {
  const page = data.orchard.puxDesignReference[0]
  const sliderRef = React.useRef<HTMLDivElement>(null)
  const [isPictureLoaded, setPictuLoaded] = React.useState(false)

  let { LOCALE } = process.env
  LOCALE = LOCALE ? LOCALE : `cs`

  const handlePictureLoad = () => {
    setPictuLoaded(true)
  }

  const handleScroll = () => {
    const anchors = Array.from(document.querySelectorAll(".relative-anchor[id]"))
    const offset = window.pageYOffset;

    const filtered = anchors.filter(anchor => anchor.getBoundingClientRect().y > 5)
    const position = anchors.length - filtered.length;

    Array.from(document.querySelectorAll(`.service-nav .btn-link`)).map(link => link.classList.remove("active"));

    if (position) {
      const id = anchors[position - 1].getAttribute('id');
      document.querySelector(`.service-nav .btn-link[href*='#${id}']`)?.classList.add('active')
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <>
      <Layout localizedPath={page.localization?.localizations}>
        <PuxMetaTags {...pageContext.metaTagsData} />
        <BreadCrumbs items={pageContext.breadcrumbs} center={false} />
        <section>
          <div id="top" className="top-image case">
            <div className="images-wrap">
              <div className="images">
                <img
                  className="left"
                  src={page.puxDesignReferenceTopImagePicture?.puxMediaSelectorImage?.resizePaths[0]}
                  alt={page.displayText}
                />
                <img className="right" src={PRight} alt="" onLoad={() => handlePictureLoad()} />
              </div>
            </div>
            <div className="content-wrap">
              <div className="pux-container">
                <div className="content">
                  <h1>{page.displayText}</h1>
                  <div
                    className="perex"
                    dangerouslySetInnerHTML={{
                      __html: page.puxDesignReferenceTopImageText.html?.replace(/font-size: 1rem;/g, ``),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <StickyContainer>
          <div className="case-study-nav-helper">
            <Sticky bottomOffset={-100} topOffset={-85}>
              {({ style, isSticky }: { style: React.CSSProperties; isSticky: boolean }) => (
                <div className={`service-nav-wrap` + (isSticky ? ` fixed` : ``)} style={style}>
                  <div className="pux-container">
                    <div className="service-nav">
                      <div className="page-context">
                        <span>{page.displayText}</span>
                        <AnchorLink to={`${getLocalizedUrl(page.path)}#top`} className={`scroll-to-top${isSticky ? ' show-anchor' : ''}`}></AnchorLink>
                      </div>
                      {renderNav(page.path, page.caseStudyNavigation.contentItems)}
                      <AnchorLink
                        to={`${getLocalizedUrl(page.path)}#sib-form-scroll-helper`}
                        className="btn btn-primary btn-big"
                      >{t`PuxDesign.Service.ContactUs`}</AnchorLink>
                    </div>
                  </div>
                </div>
              )}
            </Sticky>
          </div>

          <section className="case-study-description">
            <div className="pux-container">
              <PuxTrumbowyg content={page.puxDesignReferenceDescription} />
            </div>
          </section>
          <section className="case-study-condition bg-grey triangle-cross">
            <div id="stav-pred-a-po" className="relative-anchor secondary-nav-helper"></div>
            <div className="pux-container">
              <h2>{t`PuxDesign.CaseStudy.BeforeAfterHeading`}</h2>
              <div className="case-study-condition-inner">
                <div className="case-study-condition-before">
                  <h3>{t`PuxDesign.CaseStudy.BeforeHeading`}</h3>
                  <PuxTrumbowyg content={page.puxDesignReferenceConditionBefore} />
                </div>
                <div className="case-study-condition-after">
                  <h3>{t`PuxDesign.CaseStudy.AfterHeading`}</h3>
                  <PuxTrumbowyg content={page.puxDesignReferenceConditionAfter} />
                </div>
              </div>
            </div>
          </section>
          <section className="case-study-parameters">
            <div className="pux-container">
              <div className="case-study-parameters-container">
                <div className="case-study-parameters-box">
                  <div className="case-study-patameters-item icon-price">{page.puxDesignReferenceDisplayPrice}</div>
                </div>
                <div className="case-study-parameters-box">
                  <div
                    className={`case-study-patameters-item icon-focus icon-focus-${page.puxDesignReferenceIndustryFocus}`}
                  >
                    {page.puxDesignReferenceIndustryFocus}
                  </div>
                </div>
                {page.puxDesignReferenceLink?.url?.length && (
                  <div className="case-study-parameters-box icon-link">
                    <a
                      href={`${page.puxDesignReferenceLink.url}`}
                      target="_blank"
                      className="case-study-patameters-item btn-link"
                    >
                      {page.puxDesignReferenceLink.text}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className={page.puxDesignReferenceCompanyLogo ? 'applied-services-wrap' : ''}>
            <div className="pux-container">
              <div id="dodane-sluzby" className="relative-anchor secondary-nav-helper"></div>
              <h2 className="center-content mb-50 mt-20">{t`PuxDesign.CaseStudy.AppliedServices`}</h2>
              <div className="applied-services slick-slider-helper" ref={sliderRef}>
                <Slider dots={false} infinite={false} slidesToShow={getSlideCount(sliderRef)}>
                  {page.referenceAppliedServices?.contentItems.map((item, index) => (
                    <div
                      className={`service-slide ${item.puxDesignServiceNavigationItemService.contentItems[0]?.puxDesignThemeSelector.puxDesignThemeSelectorTheme}`}
                      key={index}
                    >
                      <Link to={getLocalizedUrl(item.puxDesignServiceNavigationItemService.contentItems[0]?.path)}>
                        <Icon
                          id={
                            item.puxDesignServiceNavigationItemService.contentItems[0]?.puxDesignIconSelector
                              .puxDesignIconSelectorIcon
                          }
                          color={
                            item.puxDesignServiceNavigationItemService.contentItems[0]?.puxDesignThemeSelector
                              .puxDesignThemeSelectorTheme == `theme-blue`
                              ? `#19b9ed`
                              : `#25d49f`
                          }
                        />
                        <h4>
                          {item.puxDesignServiceNavigationItemCustomTitle
                            ? item.puxDesignServiceNavigationItemCustomTitle
                            : item.puxDesignServiceNavigationItemService.contentItems[0].displayText}
                        </h4>
                        <div
                          className="service-slide-description"
                          dangerouslySetInnerHTML={{
                            __html: item.puxDesignServiceNavigationItemCustomDescription
                              ? item.puxDesignServiceNavigationItemCustomDescription?.replace(/font-size: 1rem;/g, ``)
                              : item.puxDesignServiceNavigationItemService.contentItems[0].puxDesignServicePageDescription?.replace(
                                /font-size: 1rem;/g,
                                ``
                              ),
                          }}
                        ></div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
          <section className="case-study-description-bottom-picture">
            <div className="pux-container">
              <Picture puxMediaSelectorImage={page.puxDesignReferenceCompanyLogo} alt={page.displayText} />
            </div>
          </section>
          <section className="case-study-description-bottom bg-grey">
            <div id="pribeh" className="relative-anchor secondary-nav-helper"></div>
            <div className="pux-container">
              <PuxTrumbowyg content={page.puxDesignReferenceBottomDescription} />
            </div>
          </section>
          <div className="reference-detail-wrap">
            <WidgetBuilder widgetBuilderData={pageContext.widgets} />
          </div>
        </StickyContainer>
        <section className={`triangle-cross triangle-cross-white service-form-section`}>
          <div id="sib-form-scroll-helper" className="relative-anchor"></div>
          <div className="pux-container narrow">
            <div className="form-info">
              <h2 className="mb-0">{t`PuxDesign.CaseStudy.Form.Heading`}</h2>
              <div className="perex">{t`PuxDesign.CaseStudy.Form.Description1`}<br />{t`PuxDesign.CaseStudy.Form.Description2`}</div>
              <div className="info-tom-wrap">
                <div className="info-tom">
                  <img src={JirkaPhoto} alt="Jiří Juhaňák"></img>
                  <strong>
                    Jiří Juhaňák
                  </strong>
                  <span> / {`${LOCALE === 'cs' ? 'KONZULTANT' : 'CONSULTANT'}`}</span>
                </div>
                <div className="info-tom-phone">
                  <a href="tel:+420605175519">
                    <Icon id="mobile" color="#FFFFFF" />
                    +420 605 175 519
                  </a>
                </div>
                <div className="info-tom-mail">
                  <a href="mailto:jiri.juhanak@puxdesign.cz">
                    <Icon id="mail" color="#FFFFFF" />
                    <span className="btn-link">jiri.juhanak@puxdesign.cz</span>
                  </a>
                </div>
              </div>
            </div>
            <FormBuilder formId="general" recaptchaUrl={pageContext.recaptchaUrl} />
          </div>
        </section>
      </Layout>
    </>
  )
}

interface NavItemProps {
  contentItemId: string
  puxDesignNavigationItemAnchor: string
  puxDesignNavigationItemText: string
}

const renderNav = (path: string, items: [NavItemProps]) => {
  return (
    <nav>
      {items.map(item => {
        return (
          <AnchorLink
            className="btn-link"
            to={`${getLocalizedUrl(path)}#${item.puxDesignNavigationItemAnchor}`}
            title={item.puxDesignNavigationItemText}
            key={item.contentItemId}
          >
            {item.puxDesignNavigationItemText}
          </AnchorLink>
        )
      })}
    </nav>
  )
}

const getSlideCount = (sliderRef: React.RefObject<HTMLDivElement>): number => {
  /*const width = sliderRef.current?.clientWidth ? sliderRef.current?.clientWidth : 400
  return Math.max(1, Math.min(4, Math.floor(width / 360)))*/
  if (typeof window !== "undefined") {
    if (window.innerWidth < 991) {
      return 1
    } else if (window.innerWidth >= 991 && window.innerWidth < 1200) {
      return 2
    } else {
      return 4
    }
  } else {
    return 1
  }
}

export default CaseStudy

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignReference(first: 1, where: { path: $pagePath }) {
        displayText
        path
        puxDesignReferenceDisplayTitle
        contentItemId
        localization {
          localizations {
            ... on Orchard_PuxDesignReference {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        caseStudyNavigation {
          contentItems {
            ... on Orchard_PuxDesignNavigationItem {
              displayText
              puxDesignNavigationItemText
              puxDesignNavigationItemAnchor
              contentItemId
            }
          }
        }
        puxDesignReferenceDescription {
          html
        }
        puxDesignReferenceDisplayTitle
        puxDesignReferenceIndustryFocus
        puxDesignReferenceDisplayPrice
        puxDesignReferenceLink {
          text
          url
        }
        puxDesignReferenceTopImagePicture {
          puxMediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxDesignReferenceCompanyLogo {
          resizePathsTablet(width: 240, quality: 90, mode: "Max")
          resizePathsMobile(width: 240, quality: 90, mode: "Max")
          resizePaths(width: 240, quality: 90, mode: "Max")
        }
        puxDesignReferenceTopImageText {
          html
        }
        puxDesignReferenceConditionBefore {
          html
        }
        puxDesignReferenceConditionAfter {
          html
        }
        puxDesignReferenceBottomDescription {
          html
        }
        referenceAppliedServices {
          contentItems {
            ... on Orchard_PuxDesignServiceNavigationItem {
              createdUtc
              displayText
              puxDesignServiceNavigationItemCustomDescription
              puxDesignServiceNavigationItemCustomTitle
              puxDesignServiceNavigationItemService {
                contentItems {
                  ... on Orchard_PuxDesignServicePage {
                    displayText
                    puxDesignIconSelector {
                      puxDesignIconSelectorIcon
                    }
                    path
                    puxDesignThemeSelector {
                      puxDesignThemeSelectorTheme
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
